// .ant-notification-notice{
//     width: 100%!important;
//     .ant-notification-notice-message{
//         margin: 0!important;
//         padding: 0!important;
//         font-weight: 500!important;
//         white-space: nowrap;
//     }
// }
// .ant-notification-notice-with-icon{
//     display: flex;
//     justify-content: center;
// }
// .ant-notification-notice-close{
//     width: 0!important;
// }
// .ant-notification-notice-description{
//     margin-left: 12px!important;
// }
// .ant-notification-notice-icon{
//     // position: relative!important;
//     position: initial !important;
//     line-height: initial !important;
//     top:12px;
//     margin-right: 12px;
//     .iconfont{
//         font-size: 30px;
//         &.icon-icon-note{
//             color: #F69337;
//         }
//         &.icon-icon-Finish{
//             color: #4CE96E;
//         }
//     }
// }
.iconfont{
    &.icon-icon-note{
        color: #F69337;
    }
    &.icon-icon-Finish{
        color: #4CE96E;
    }
}