.scroll-top{
    position: fixed;
    right: 64px;
    bottom: 87px;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background: #7863FB;
    color: #fff;
    z-index: 99;
    .iconfont{
        font-size: 28px;
    }
    transition: all .5s ease-out;
    &.hidder{
        opacity: 0;
    }
}