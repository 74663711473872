    .inputChange{
        width:100%;
        .addonBefore{
            display:'flex';
            align-items:'center';
        }
        .clearAfter{
            display:'flex';
            align-items:'center';
            text-align: center;
            min-width: 40px;
        }
        .inputType{
            width: 100%;
            height: 40px;
            position: relative;
            display: flex;
            align-items: center;
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid transparent;
            input{
                width: 100%;
                padding-left: 8px;
                height: 100%;
                border: none;
                background: transparent;
                background: transparent;
                overflow: hidden; 
                white-space: nowrap; 
                text-overflow: ellipsis;
            }
            input::placeholder{
                color: #8B8B90;
            }
            input:focus {
                outline: none;
              }
            .rightName{
                // padding-right:10px;
                text-align: right;
            }
            .pointer.rightName{
                cursor: pointer;
            }
        }
        .inputType{
            &.err{ border-color: transparent !important; }
            &.small{ height:32px; }
            &.middle{ height:42px; }
            &.large{ height:48px; }
            &.moreLarge{ height: 52px; }
            &.smallWidth{ width: 100px; }
            &.noborder{
                border: 0;
                input{padding-left: 0;}
                .rightName{padding-right:0px;}
            }

        }
        .errTip{
            opacity: 0;
            // color: $light_auxiliary_2;
            margin: 0;
            font-size: 12px;
        }
        .errTip.err{
            opacity: 1;
        }

    }



