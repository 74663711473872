
.email-modal{
    background: linear-gradient(106.62deg, #FCD977 20.45%, #FF53FE 45.16%, #6700FF 65.01%, #20FFE4 87.85%);
    border-radius: 12px !important;
    .contact-text{
        gap: 2px;
        @media screen and(max-width:1024px) {
            flex-direction: column;
        }
    }
    .ant-modal-content{
        border-radius: 12px !important;
        background-color:#14141B;
        border: 1px solid transparent;
        padding: 48px;
        .copy-email-btn{
            margin-top: 24px;
            padding: 12px 24px;
            font-size: 18px;
            border-radius: 500px;
            background: #7863FB;
            .copy-btn-status{
                position: relative;
                width: 20px;
                height: 20px;
                overflow: hidden;
                &.suc{
                    .icon-icon-copy{
                        top: -20px;
                    }
                    .suc-bg{
                        top: 0px;
                    }
                }
                .icon-icon-copy{
                    position: absolute;
                    top: -3px;
                    transition: all 0.2s;
                }
                .suc-bg{
                    flex-shrink: 0;
                    position: absolute;
                    top: 20px;
                    width: 18px;
                    height: 18px;
                    background: #fff;
                    border-radius: 50%;
                    color: #7863FB;
                    transform: scale(0.8);
                    transition: all 0.2s;
                    .icon-icon-yep{
                        margin-top: 2px;
                    }
                }
    
            }
        }
    }
    .ant-modal-close-x{
        display: none;
    }
    .ant-modal-header{
        background-color:#14141B;
    }
}